import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";

import tokens from "../../../styles/tokens/tokens";

import { toCamelCase } from "utils/case";
import sendGAEvent from "utils/sendGAEvent";

import useActivityContext from "hooks/useActivityContext";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ellipsisMenuItem: {
    display: "block",
    width: "100%",
    textAlign: "center",
    color: colours.oldSecondary,
    borderBottom: "1px #DDD solid",

    ":last-child": {
      borderBottom: "none",
    },
  },
};

const buttonStyles = {
  button: {
    display: "block",
    paddingLeft: "1em",
    paddingRight: "1.5em",
    textAlign: "left",
    color: colours.black,

    [ScreenSizes.mdAndBelow]: {
      padding: "1.2rem",
      fontSize: "1.05rem",
      textAlign: "center",
    },
  },
  hover: {
    backgroundColor: "#f7f7f7",
  },
};

const buttonStylesActive = {
  ...buttonStyles,
  button: {
    ...buttonStyles.button,

    ":not(:hover)": {
      color: tokens.color.neutral.tint,
    },
  },
};

const EllipsisMenuItem = (props) => {
  const {
    children,
    buttonStyles: passedButtonStyles,
    onClick,
    actioned,
    analyticsProps,
    ...rest
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const activityContext = useActivityContext();

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }

      sendGAEvent({
        action: `ellipsisMenuClicked-${toCamelCase(props.label)}`,
        ...activityContext,
        ...analyticsProps,
      });
    },
    [activityContext, analyticsProps, onClick, props.label]
  );

  return (
    <div className={css(styles.ellipsisMenuItem)}>
      {children || (
        <StandardButton
          variation="transparent"
          overwriteStyles={passedButtonStyles || buttonStylesActive}
          flat
          onClick={handleClick}
          {...rest}
        />
      )}
    </div>
  );
};

EllipsisMenuItem.propTypes = {
  buttonStyles: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  actioned: PropTypes.bool,
  analyticsProps: PropTypes.object,
};

EllipsisMenuItem.defaultProps = {
  onClick: null,
  buttonStyles: null,
  children: null,
  actioned: false,
  analyticsProps: {},
};

export default EllipsisMenuItem;
