import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";

import EllipsisButton from "components/Buttons/EllipsisButton";
import Steps from "components/Common/Steps";

import sendGAEvent from "utils/sendGAEvent";

import useActivityContext from "hooks/useActivityContext";
import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";

const baseStyles = {
  ellipsisPopoverContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "12rem",
    position: "relative",
  },
};

const ellipsisButtonStyles = {
  ellipsisButtonInner: {
    border: "none",
    background: "none",
    color: "var(--color-neutral-d6)",
  },
};

const EllipsisMenuButton = (props) => {
  const {
    renderContent: passedRenderContent,
    steps,
    analyticsVariables,
    closeOnOutsideclick,
    subMenuOpen,
    setSubMenuOpen,
    passedEllipsisPopoutStyles,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const [ellipsisIsOpen, setEllipsisIsOpen] = useState(false);

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const wrapperRef = useOutsideClickHandler(() => setEllipsisIsOpen(false));

  const [currentStep, setCurrentStep] = useState(steps && steps[0]);

  const feedActivityContext = useActivityContext();

  const closeEllipsisPopover = useCallback(() => {
    setEllipsisIsOpen(false);
    setCurrentStep(steps[0]);
  }, [steps]);

  const ellipsisButtonEventListener = useCallback(
    (isOpen = false) => {
      const closeEllipsisPopoverOnEscape = (e) => {
        if (e.keyCode === 27) {
          closeEllipsisPopover();
        }
      };

      if (isOpen) {
        window.addEventListener("keydown", closeEllipsisPopoverOnEscape);
      } else {
        window.removeEventListener("keydown", closeEllipsisPopoverOnEscape);
      }
    },
    [closeEllipsisPopover]
  );

  const handleChangeStep = useCallback(
    (step) => () => setCurrentStep(step),
    []
  );
  const handleBack = useCallback(() => {
    if (setSubMenuOpen) {
      setSubMenuOpen(false);
    }
    if (steps) {
      setCurrentStep(steps[0]);
    } else {
      setCurrentStep(null);
    }
  }, [setSubMenuOpen, steps]);

  const renderContent = useCallback(
    (renderProps) => (
      <div className={css(styles.ellipsisPopoverContainer)}>
        {passedRenderContent(renderProps)}
      </div>
    ),
    [passedRenderContent, styles.ellipsisPopoverContainer]
  );

  const renderEllipsisContent = useCallback(
    (downshiftProps) => {
      const content = renderContent({
        ...downshiftProps,
        onChangeStep: handleChangeStep,
        currentStep,
        onBack: handleBack,
        closeEllipsisPopover,
      });

      if (steps && steps.length > 0) {
        return (
          <Steps
            steps={steps}
            currentStep={content}
            currentStepIndex={steps.indexOf(currentStep) || 0}
          />
        );
      }

      return content;
    },
    [
      renderContent,
      handleChangeStep,
      currentStep,
      handleBack,
      closeEllipsisPopover,
      steps,
    ]
  );

  const onButtonToggle = useCallback(
    (isOpen) => {
      setEllipsisIsOpen(isOpen);
      ellipsisButtonEventListener(isOpen);

      if (!isOpen) {
        handleChangeStep(null)();
      }

      if (steps && steps.length > 0) {
        setCurrentStep(steps[0]);
      }

      sendGAEvent({
        action: `ellipsisMenu${isOpen ? "Opened" : "Closed"}`,
        ...(feedActivityContext || {}),
        ...(analyticsVariables || {}),
      });
    },
    [
      analyticsVariables,
      ellipsisButtonEventListener,
      feedActivityContext,
      handleChangeStep,
      steps,
    ]
  );
  const ellipsisPopoutProps = useMemo(() => {
    const ellipsisProps = {
      noPadding: true,
      isOpen: ellipsisIsOpen,
      styles: passedEllipsisPopoutStyles,
    };

    if (mobile) {
      return {
        ...ellipsisProps,
        slideInFrom: "bottom",
        hideArrow: mobile,
        slidingProps: {
          fullWidth: true,
          fullHeight: true,
          showOverlay: true,
          styles: {
            childrenInner: {
              // TODO: These styles can be shared between any modals that need to slide up like this on mobile
              height: "auto",
              backgroundColor: colours.white,
              position: "absolute",
              bottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
          extraOnClose: () => {
            onButtonToggle(false);
          },
        },
      };
    }

    return ellipsisProps;
  }, [ellipsisIsOpen, mobile, onButtonToggle, passedEllipsisPopoutStyles]);

  return (
    <div ref={closeOnOutsideclick && !subMenuOpen ? wrapperRef : null}>
      <EllipsisButton
        renderContent={renderEllipsisContent}
        styles={ellipsisButtonStyles}
        popoutProps={ellipsisPopoutProps}
        blockToggleOnContentClick
        onClick={onButtonToggle}
      />
    </div>
  );
};

EllipsisMenuButton.propTypes = {
  renderContent: PropTypes.func.isRequired,
  steps: PropTypes.array,
  analyticsVariables: PropTypes.object,
};

EllipsisMenuButton.defaultProps = {
  steps: [],
  analyticsVariables: null,
};

export default memo(EllipsisMenuButton);
